@import "../../scss/colors.scss";

.wrapper {
  width: 384px;
  text-align: center;
  margin-top: 64px;
}

.title {
  color: $white;
  font-size: 30px;
  line-height: 40px;
  font-family: "Inter";
  font-weight: 700;
  margin-top: 24px;
}

.description {
  color: $white;

  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  text-align: center;
  font-feature-settings: "liga" off;

  color: #a7adb7;
}
