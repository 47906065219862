@import "../../scss/colors.scss";

.wrapper {
    width: 135px;
    height: 40px;
    padding-right: 10px;
    padding-left: 10px;
    background-color: $black;
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #a6a6a6;
    &:hover {
        cursor: pointer;
    }
}

.textWrapper {
    margin-left: 8px;
}

.title {
    color: $white;
    font-size: 13px;
    font-weight: 600;
}

.subtitle {
    font-size: 8px;
    font-weight: 600;
    color: $white;
}
