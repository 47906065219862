@import "../../scss/colors.scss";

.wrapper {
  width: 400px;
  text-align: center;
}

.title {
  color: $white;
  font-size: 43px;
  line-height: 58px;
  font-weight: 600;
  font-family: "Open Sans";
  border-bottom: 5px solid #fff;
}

.subtitle {
  margin-top: 8px;
  color: $gray;
  font-size: 15px;
  line-height: 25px;
  font-weight: 400;
  font-family: "Open Sans";
}
