/* inter-regular - latin */
@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    src: url("../scss/fonts/inter-v8-latin-regular.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../scss/fonts/inter-v8-latin-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../scss/fonts/inter-v8-latin-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../scss/fonts/inter-v8-latin-regular.woff") format("woff"),
        /* Modern Browsers */ url("../scss/fonts/inter-v8-latin-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../scss/fonts/inter-v8-latin-regular.svg#Inter") format("svg"); /* Legacy iOS */
}
/* inter-600 - latin */
@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    src: url("../scss/fonts/inter-v8-latin-600.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../scss/fonts/inter-v8-latin-600.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../scss/fonts/inter-v8-latin-600.woff2")
            format("woff2"),
        /* Super Modern Browsers */ url("../scss/fonts/inter-v8-latin-600.woff")
            format("woff"),
        /* Modern Browsers */ url("../scss/fonts/inter-v8-latin-600.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../scss/fonts/inter-v8-latin-600.svg#Inter") format("svg"); /* Legacy iOS */
}
/* inter-700 - latin */
@font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    src: url("../scss/fonts/inter-v8-latin-700.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../scss/fonts/inter-v8-latin-700.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../scss/fonts/inter-v8-latin-700.woff2")
            format("woff2"),
        /* Super Modern Browsers */ url("../scss/fonts/inter-v8-latin-700.woff")
            format("woff"),
        /* Modern Browsers */ url("../scss/fonts/inter-v8-latin-700.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../scss/fonts/inter-v8-latin-700.svg#Inter") format("svg"); /* Legacy iOS */
}

/* open-sans-regular - latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    src: url("../scss/fonts/open-sans-v28-latin-regular.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../scss/fonts/open-sans-v28-latin-regular.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../scss/fonts/open-sans-v28-latin-regular.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../scss/fonts/open-sans-v28-latin-regular.woff") format("woff"),
        /* Modern Browsers */
            url("../scss/fonts/open-sans-v28-latin-regular.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../scss/fonts/open-sans-v28-latin-regular.svg#OpenSans")
            format("svg"); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    src: url("../scss/fonts/open-sans-v28-latin-600.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../scss/fonts/open-sans-v28-latin-600.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../scss/fonts/open-sans-v28-latin-600.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../scss/fonts/open-sans-v28-latin-600.woff") format("woff"),
        /* Modern Browsers */ url("../scss/fonts/open-sans-v28-latin-600.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../scss/fonts/open-sans-v28-latin-600.svg#OpenSans")
            format("svg"); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    src: url("../scss/fonts/open-sans-v28-latin-700.eot"); /* IE9 Compat Modes */
    src: local(""),
        url("../scss/fonts/open-sans-v28-latin-700.eot?#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../scss/fonts/open-sans-v28-latin-700.woff2")
            format("woff2"),
        /* Super Modern Browsers */
            url("../scss/fonts/open-sans-v28-latin-700.woff") format("woff"),
        /* Modern Browsers */ url("../scss/fonts/open-sans-v28-latin-700.ttf")
            format("truetype"),
        /* Safari, Android, iOS */
            url("../scss/fonts/open-sans-v28-latin-700.svg#OpenSans")
            format("svg"); /* Legacy iOS */
}
