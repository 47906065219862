@import "../../scss/colors.scss";

.wrapperRight {
    max-width: 290px;
    text-align: start;
    min-height: 165px;
}

.wrapperLeft {
    max-width: 290px;
    text-align: end;
    min-height: 165px;
}

.title {
    color: $dark;
    font-size: 18px;
    line-height: 29px;
    font-weight: 700;
    font-family: "Open Sans";
    margin-top: 4px;
}

.subtitle {
    color: $gray;
    font-size: 15px;
    line-height: 25px;
    font-weight: 400;
    font-family: "Open Sans";
    margin-bottom: 40px;
}
