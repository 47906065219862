@import "colors";
@import "libs/normalize";
@import "fonts";
.myImage {
  height: 50px;
  width: 50px;
}
.myTitle {
  margin-top: 15px;
  margin-bottom: 15px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 29px;

  color: #ffffff;
}
.myDescription {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  color: #a7adb7;
}
.myBlock {
  margin-bottom: 30px;
}
.myWrapper {
  background-image: url("../assets/images/Background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.section-header {
  // background-image: url("../assets/images/HeaderBackground.png");
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center;
  // height: 866px;
}

._ibg {
  position: relative;

  &img {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}

.section-outer {
}

.section-wrapper {
  max-width: 1216px;
  margin: 0 auto;
}

.logo-wrapper {
  display: flex;
  align-items: center;
}

.logo-title {
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  margin: 6px;
  color: $dark;
}

.logo-titleBold {
  font-weight: 700;
  font-size: 15px;
  color: $primary;
}

.logo {
  width: 50px;
  height: 36px;
}

.main-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;

  &-wrapper {
    display: flex;
    align-items: center;
  }
}

.nav-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 70px;

  &__item {
    margin-left: 16px;
    &-link {
      font-family: "Inter";
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #f8f8f8;
      padding: 4px 8px;
      &:hover {
        color: $gray;
        cursor: pointer;
      }
    }
  }
}

.nav-btnWrapper {
  display: flex;
  width: 280px;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.header-content {
  padding-top: 74px;
  padding-bottom: 74px;
  display: flex;
  justify-content: space-between;

  &__description {
    width: 55%;
    margin-top: 96px;
    &-title {
      font-family: "Inter";
      font-size: 60px;
      color: $white;
      line-height: 72px;
      font-weight: 700;
      margin-bottom: 16px;
    }
    &-subtitle {
      font-family: "Inter";
      font-size: 18px;
      line-height: 28px;
      font-weight: 400;
      color: $white;
    }
  }

  &__preview {
    width: 40%;

    & img {
      margin-left: 13%;
      width: 640x;
      height: 488px;
    }
  }
}

.section-features {
  // background-color: $white;
}

.features-content {
  padding-top: 96px;
  padding-bottom: 96px;
  &__title {
    text-align: center;
    font-family: "Inter";
    font-size: 48px;
    line-height: 60px;
    color: $white;
    margin-bottom: 8px;
  }

  &__subtitle {
    text-align: center;
    font-family: "Inter";
    font-size: 18px;
    line-height: 28px;
    color: $white;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.section-hyperframe {
  // background-image: url("../assets/images/HyperframeBackground.png");
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center;
  // height: 358px;
}

.hyperframe-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 358px;
}

.hyperframe-content {
  width: 644px;

  &__title {
    font-family: "Open Sans";
    font-size: 30px;
    line-height: 49px;
    font-weight: 700;
    color: $white;
    text-align: center;
  }

  &__subtitle {
    font-family: "Open Sans";
    font-size: 20px;
    line-height: 32px;
    font-weight: 400;
    color: $white;
    text-align: center;
    margin-top: 12px;
  }
}
.section-price {
  // background-color: $white;
}

.price-content {
  &__items-wrapper {
    margin-top: 27px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.wireframe-content {
  margin-top: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 80px;

  &__left {
  }

  &__center {
    &-img {
      width: 600px;
      height: 533px;
    }
  }
  &__right {
  }
}

.section-info {
  background-image: url("../assets/images/Background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 644px;
}

.section-map {
  background-image: url("../assets/images/Map.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 376px;
  width: 80%;
  margin: 0 auto;
}

.section-map-wrapper {
  height: 376px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.map-modal {
  width: 280px;
  height: 280px;
  background-color: #1f1f1f;
  border-radius: 5px;
  padding: 24px 30px;

  &__title {
    font-family: "Open Sans";
    font-size: 22px;
    line-height: 36px;
    color: $white;
    font-weight: 700;
  }

  &-list {
    margin-left: 20px;
    margin-top: 23px;
    &__item {
      list-style: square;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 29px;

      color: #a7adb7;
    }
  }
  &-social-wrapper {
    margin-top: 34px;
    width: 100px;
    display: flex;
    justify-content: space-between;

    &__link:hover {
      cursor: pointer;
    }
  }
}

.section-help {
  // background-color: $white;
}

.help-content-wrapper {
  height: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.help-content {
  &__title {
    color: $white;
    font-size: 30px;
    line-height: 49px;
    font-family: "Open Sans";
    font-weight: 700;
    text-align: center;
  }

  &__subtitle {
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 33px;
    text-align: center;

    color: #a7adb7;
    font-family: "Open Sans";
  }

  &__form {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 28px;
    flex-wrap: wrap;

    & input {
      width: 300px;
      height: 50px;
      margin-right: 12px;
      padding: 0px 30px;
      color: $black;
      font-family: "Open Sans";
      font-size: 14px;
      font-weight: 400;
      background-color: #f0f0f0;
      border: 1px solid #c6c6c6;
      border-radius: 5px;
    }

    &-button {
      width: 240px;
      height: 50px;
      background: linear-gradient(75.68deg, #8806e2 16.52%, #dd20f1 102.13%);

      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      &__title {
        color: $white;
        font-family: "Open Sans";
        font-size: 14px;
        font-weight: 600;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.section-footer {
  // background-image: url("../assets/images/FooterBackground.png");
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-position: center;
  // height: 70px;
  background: #1f1f1f;
}

.logo-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
}
